import React from 'react'
import IconDribbble from "../assets/social-db.svg"
import IconInstagram from "../assets/social-ig.svg"
import IconLinkedin from "../assets/social-in.svg"

var SocialIcons = ( props ) => 
{
    return (
        <div className='social'>
            <ul>
                <li>
                    <a href='https://instagram.com/jwztr'>
                    <IconInstagram className='social__icon'/>
                    </a>
                </li>
                <li>
                    <a href='https://www.linkedin.com/in/jurajweszter'>
                    <IconLinkedin className='social__icon'/>
                    </a>
                </li>
                <li>
                    <a href='https://dribbble.com/jurajw'>
                    <IconDribbble className='social__icon'/>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default SocialIcons;
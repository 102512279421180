import React from 'react'
import classNames from 'classnames'
import { useInView } from "react-intersection-observer"

function ButtonWrapper(props) {

    const [ref, inView] = useInView({ threshold: 0.15, triggerOnce: true })

    var ClassNames = classNames({
        'buttonWrapper' : true,
        'revealOnScroll' : props.revealOnScroll
    });

    return (
        <div  className={ClassNames + (props.revealOnScroll && inView ? ' isVisible' : '')} ref={props.revealOnScroll ? ref : null} style={{'--delay': props.delay}}>
            { props.children }
        </div>
    );
}

export default ButtonWrapper;
import React from 'react'
import classNames from 'classnames';
import './button.scss'

function Button(props) {
    
    var ClassNames = classNames({
        'button' : true,
        'button--basic--black' : props.background === 'black' && props.buttonStyle ==='basic',
        'button--basic--charcoal' : props.background === 'charcoal' && props.buttonStyle ==='basic',
        'button--basic--purple' : props.background === 'purple' && props.buttonStyle ==='basic',
        'button--basic--salmon' : props.background === 'salmon' && props.buttonStyle ==='basic',
        'button--size-500' : props.size ==='500',
        'button--arrow' : props.buttonStyle === 'arrow',
        'button--basic' : props.buttonStyle === 'basic',
        'button--dot' : props.icon === 'dot'

    });

    return (
        <a className={ClassNames} href={props.href}>
            <div className='button__inner'>
                { props.children }
            </div>
        </a>
    );
}

export default Button;
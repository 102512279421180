import React from 'react'
import './layout.scss'
import './nav.scss'
import './typography.scss'
import Button from './button'
import Title from './title'
import Dot from '../images/dot.svg'

const Nav = (props) => 
(
    <div className={`nav`}>
        <div className={'nav__inner'}>
            <Title size='300'>
                Juraj Weszter
            </Title>
            { !props.return && 
            <div className='hide-early'>
                <ul>
                    <li>
                        <a className={'nav__link'} href='#services'>Services</a>
                    </li>
                    <li>
                        <a className={'nav__link'} href='#work'>Work</a>
                    </li>
                    <li>
                        <a className={'nav__link'} href='#about'>About me</a>
                    </li>
                </ul>
            </div>
            }
            { props.return &&
            <div className='hide-early'>
                <ul>
                    <li>
                        <a className={'nav__link'} href='/'>Back to Homepage</a>
                    </li>
                </ul>
            </div>
            }
            <div className='hide-late' style={{flexGrow: '1'}} />
            <div className='hide-late'>
                <Button href='mailto:jweszter@gmail.com' buttonStyle='basic' background='black'>
                    <img src={Dot} alt='' class='button__icon button__icon--dot'/>
                    jweszter@gmail.com
                </Button>
            </div>
            <Button buttonStyle='basic' background='blue' href='#contact'>
                Get in touch
            </Button>
        </div>
    </div>
)

export default Nav;
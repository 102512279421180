import React from 'react'
import classNames from 'classnames';

function Wrapper(props) {

    var desiredClassNames = classNames({
        'wrapper' : true,
        'wrapper--align-center': props.align === 'center',
        'wrapper--align-top': props.align === 'top',
        'wrapper--padded': props.padded === 'true',
        'wrapper--columns': props.content === 'columns',
        'wrapper--columns--1': props.content === 'columns' && props.columnCount === '1',
        'wrapper--columns--2': props.content === 'columns' && props.columnCount === '2',
        'wrapper--columns--3': props.content === 'columns' && props.columnCount === '3',
        'wrapper--columns--4': props.content === 'columns' && props.columnCount === '4',
        'wrapper--cards': props.content === 'cards',
        'wrapper--gallery': props.content === 'gallery',
        'wrapper--stretch': props.stretch === 'true',
        'wrapper--reverse': props.mobileOrder === 'reverse'
    });

    return (
        <div className={desiredClassNames}>
            { props.children }
        </div>
    );
}

export default Wrapper;
import React from 'react'
import classNames from 'classnames'
import { Link } from "gatsby"

function GalleryItem(props) {
    
    
    var ClassNames = classNames({
        'galleryItem' : true,
        'galleryItem--third' : props.span === 'third',
        'galleryItem--half' : props.span === 'half',
        'galleryItem--full' : props.span === 'full',
    });

    return (
        <div className={ClassNames}>
            <Link to={props.linkTo}>
                { props.children }
            </Link>
        </div>
    );
}

export default GalleryItem;

import React from 'react'
import classNames from 'classnames';
import { useInView } from "react-intersection-observer"

function Column(props) {

    const [ref, inView] = useInView({ threshold: 0.25, triggerOnce: true })
    
    var ClassNames = classNames({
        'column' : true,
        'column--width-50': props.width === '50',
        'column--width-40': props.width === '40',
        'column--width-100': props.width === '100',
        'column--spacing-400': props.spacing === '400',
        'column--spacing-500': props.spacing === '500',
        'column--spacing-600': props.spacing === '600',
        'shift--075em': props.shift === '075em',
        'column--sticky': props.sticky ==='true',
        'column--centered': props.align ==='center',
        'column--right': props.align ==='right',
        'column--mobileAlign-center': props.mobileAlign ==='center',
        'column--mobileAlign-right': props.mobileAlign ==='right',
        'column--mobileOrder-reverse': props.mobileOrder ==='reverse',
        'revealOnScroll' : props.revealOnScroll
    });

    return (
        <div className={ClassNames + (props.revealOnScroll && inView ? ' isVisible' : '')} ref={props.revealOnScroll ? ref : null} style={{'--delay': props.delay}}>
            { props.children }
        </div>
    );
}

export default Column;

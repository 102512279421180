import React from 'react'
import classNames from 'classnames'
import { useInView } from "react-intersection-observer"


function Title(props) {

    const [ref, inView] = useInView({ threshold: 0.15, triggerOnce: true })
    const TitlePriority = props.h1 ? 'h1' : (props.h2 ? 'h2' : 'div');

    var ClassNames = classNames({
        'title' : true,
        'title--50' : props.size === '50',
        'title--100' : props.size === '100',
        'title--300' : props.size === '300',
        'title--500' : props.size === '500',
        'title--700' : props.size === '700',
        'title--900' : props.size === '900',
        'revealOnScroll' : props.revealOnScroll
    });

    return (
        <TitlePriority className={ClassNames + (props.revealOnScroll && inView ? ' isVisible' : '')} ref={ref} style={{maxWidth : props.maxWidth + 'em'}}>
            { props.children }
        </TitlePriority>
    );
}

export default Title;
import React from 'react'
import Title from './title';

const Divider = (props) => {

    return (
        <div className='divider'>
            { props.title &&
                <div className='divider__title'>
                    <Title size='500'>
                        { props.title }
                    </Title>
                </div>
            }
            <div className='divider__line' />
        </div>
    );

}

export default Divider;